<template>
  <v-container fluid class="py-6">
    <v-snackbar
      top
      v-model="snackbar.visible"
      :color="snackbar.color"
      class="snackbar-shadow"
    >
      <div class="d-flex align-start alert-notify">
        <v-icon size="24" class="text-white mr-5">ni ni-bell-55</v-icon>
        <p class="mb-0">
          <span class="font-size-root font-weight-600">
            {{ snackbar.name }}
          </span>
          <br />
          {{ error_msg }}
        </p>
      </div>

      <template v-slot:action="{ attrs }">
        <v-btn
          icon
          elevation="0"
          max-width="136"
          :ripple="false"
          height="43"
          class="font-weight-600 text-capitalize py-3 px-6 rounded-sm"
          color="rgba(255,255,255, .85)"
          @click="snackbar.visible = false"
          v-bind="attrs"
        >
          <v-icon size="13">fas fa-times</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <v-row>
      <v-col cols="12">
        <v-card class="light">
          <v-app-bar color="light" class="border-left-primary">
            <v-toolbar-title>{{ $t("user.Users") }}</v-toolbar-title>
            <router-link
              style="text-decoration: none"
              :to="'/users/admins/create'"
              class="py-5 px-6 mb-0 ms-auto"
              v-if="$store.getters['auth/str_per'].indexOf('user-create') > -1"
            >
              <v-icon class="custome-btn btn-primary">fas fa-plus</v-icon>
            </router-link>
          </v-app-bar>
          <!-- {{ users }} -->
          <FormFilter
            :filters="filters"
            @get_data="getUsers"
            @reset="resetFilters"
          ></FormFilter>
          <Table
            ref="table"
            @set_option="set_option"
            @change_pre_page="ChangePrePage"
            @get_data_paginate="get_data_paginate"
            @action="action"
            @event_btn="event_btn"
            :loading="loading"
            :btn_loading="btn_loading"
            :btns="btns"
            :headers="header"
            :items="users"
            :paginate="paginate"
            :btn_table="btn_table"
          ></Table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import Table from "../../Components/PaginationTable.vue";
import FormFilter from "../../Components/Filter.vue";
import UserService from "../../../services/user.service";
export default {
  name: "Dashboard",
  components: {
    Table,
    FormFilter,
  },
  data() {
    return {
      options: {},
      filters: [],
      btn_table: {
        excel: true,
        print: true,
      },
      btn_loading: {
        excel: false,
        print: false,
      },
      users: [],
      loading: true,
      snackbar: {
        color: "#f5365c",
        class: "danger",
        name: "Danger",
        visible: false,
      },
      btns: [
        {
          type: "icon",
          text: "edit",
          color: "bg-gradient-success",
          icon: "mdi-pencil",
          permission: "user-update",
        },
      ],
      header: [
        {
          text: this.$i18n.t("group.Name"),
          value: "avatar",
          align: "center",
        },
        {
          text: this.$i18n.t("user.Email"),
          value: "users.email",
          align: "center",
        },
        {
          text: this.$i18n.t("auth.Phone Number"),
          value: "users.phone",
          align: "center",
        },
        {
          text: this.$i18n.t("general.Created at"),
          value: "users.created_at",
          align: "center",
        },
        { text: this.$i18n.t("role.Roles"), value: "roles", align: "center" },

        {
          text: this.$i18n.t("general.Action"),
          value: "btns",
          align: "center",
        },
      ],
      error_msg: "",
      paginate: {
        page: 1,
        itemsPerPage: 0,
        total: 0,
      },
    };
  },
  methods: {
    set_option(val) {
      this.options = val;
    },
    get_data_paginate(page) {
      this.paginate.page = page;
      this.getUsers();
    },
    ChangePrePage(page) {
      this.paginate.itemsPerPage = parseInt(page);
      this.paginate.page = 1;
      this.getUsers(false, "pre_page");
    },
    resetFilters() {
      this.paginate.page = 1;
      this.getUsers(true);
    },
    getUsers(reset = false, type = null) {
      this.loading = true;
      const formData = new FormData();
      if (!reset) {
        this.filters.filter(function (filter) {
          formData.append(filter.name, filter.value);
        });
      }
      if (type == "pre_page") {
        formData.append("paginate", this.paginate.itemsPerPage);
      }
      return UserService.get_admins(this.paginate.page, formData).then(
        (response) => {
          console.log("response.data.users");
          console.log(response.data);
          this.loading = false;
          this.paginate.total = response.data.users.total;
          this.paginate.itemsPerPage = parseInt(response.data.users.per_page);
          this.users = response.data.users.data;

          if (reset) {
            this.filters = response.data.filters;
            this.options = {};
            this.$refs.table.resetOption();
          }
        },
        (error) => {
          console.log(error);
          this.loading = false;
          if (error.response.status != 401) {
            this.message = {
              msg: this.$i18n.t("general.there is problem"),
              type: "Danger",
            };
          }
        }
      );
    },
    action(id, name) {
      if (name == "edit") {
        this.edit(id);
      }
    },
    edit(id) {
      this.$router.push({ path: `/users/admins/${id.toString()}` });
    },
    event_btn(name) {
      if (name == "excel") {
        this.export_excel();
      } else if (name == "print") {
        this.print_table();
      }
    },
    print_table() {
      document.querySelector("table").classList.add("print");
      window.print();
    },
    export_excel() {
      this.btn_loading.excel = true;
      const formData = new FormData();
      this.filters.filter(function (filter) {
        formData.append(filter.name, filter.value);
      });
      this.$http({
        url: this.$baseURL + "api/users/exportAdmins",
        responseType: "blob",
        method: "post",
        data: formData,
      }).then(
        (response) => {
          this.btn_loading.excel = false;
          // console.log(response);
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: "application/vnd.ms-excel",
            })
          );

          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            "Users_" + new Date().toLocaleString() + ".xlsx"
          );
          document.body.appendChild(link);
          link.click();
        },
        (error) => {
          this.btn_loading.excel = false;
          if (error.response.status != 401) {
            this.message = {
              msg: this.$i18n.t("general.there is problem"),
              type: "Danger",
            };
          }
        }
      );
    },
  },
  created() {
    if (this.$route.params.message) {
      this.message = this.$route.params.message;
      //
    }
  },
  mounted() {
    this.getUsers(true);
    document.title = this.$i18n.t("user.Users");
  },
};
</script>
